import React, { useState, FormEvent } from "react"

import { PaymentElement } from "@stripe/react-stripe-js"
import { useStripe, useElements } from "@stripe/react-stripe-js"
import { GetParameter } from "src/assets/functions"
import { MdCheckCircle } from "react-icons/md"
import { useNavigate } from "react-router-dom"

export default function CheckoutForm() {
    const stripe = useStripe()
    const elements = useElements()

    const [message, setMessage] = useState<string | null>(null)
    const [isProcessing, setIsProcessing] = useState<boolean>(false)

    const param = GetParameter("redirect_status")

    const [completed] = useState(param)

    const nav = useNavigate()

    const handleSubmit = async (e: FormEvent) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js ainda não foi carregado.
            // Certifique-se de desabilitar o envio do formulário até que o Stripe.js seja carregado.
            return
        }

        setIsProcessing(true)

        const { error } = await stripe.confirmPayment({
            elements: elements,
            confirmParams: {
                // Certifique-se de alterar para a página de conclusão de pagamento
                return_url: `${window.location.origin}/checkout`,
            },
        })

        if (error) {
            if (
                error.type === "card_error" ||
                error.type === "validation_error"
            ) {
                setMessage(error.message || "An error occurred")
            } else {
                setMessage("An unexpected error occurred.")
            }
        } else {
            setMessage(null)
        }

        setIsProcessing(false)
    }

    return (
        <form id="payment-form" onSubmit={handleSubmit}>
            {!completed ? (
                <>
                    <PaymentElement id="payment-element" />
                    <div style={{ flexDirection: "column" }}>
                        <button
                            disabled={isProcessing || !stripe || !elements}
                            id="submit"
                        >
                            <span id="button-text">
                                {isProcessing
                                    ? "Processando..."
                                    : "Pagar Agora"}
                            </span>
                        </button>
                        {message && <div id="payment-message">{message}</div>}
                    </div>
                </>
            ) : (
                <div style={{ flexDirection: "column", alignItems: "center" }}>
                    <MdCheckCircle color="#3f3" size={68} />
                    <p>Pagamento concluído!</p>
                    <button onClick={() => nav("/")}>Voltar</button>
                </div>
            )}

            {/* Exibe qualquer mensagem de erro ou sucesso */}
        </form>
    )
}
