import React, { useEffect, useState } from "react"

import { Elements } from "@stripe/react-stripe-js"
import { loadStripe, Stripe, StripeElementsOptions } from "@stripe/stripe-js"

import { Card, Container, Horzontal, Info, PlanInfo } from "./styles"
import api from "src/API/rest"
import CheckoutForm from "./CheckoutForm"
import { ThreeDots } from "react-loader-spinner"
import {
    MdCheck,
    MdCheckCircleOutline,
    MdRemoveCircleOutline,
} from "react-icons/md"
import { FaQuestion, FaQuestionCircle } from "react-icons/fa"
import plans from "./plans"

type StripePromise = PromiseLike<Stripe | null> | null

const Checkout: React.FC = () => {
    const [stripePromise, setStripePromise] = useState<StripePromise>(null)
    const [clientSecret, setClientSecret] = useState<{ clientSecret: string }>()

    useEffect(() => {
        api.post("/stripe/config/")
            .then(async (r) => {
                const { publishableKey } = await r.data
                setStripePromise(loadStripe(publishableKey))
            })
            .catch((err) => alert(err))
    }, [])

    useEffect(() => {
        api.post<{ clientSecret: string }>("/stripe/create-intent/")
            .then(async (r) => {
                setClientSecret(r.data)
            })
            .catch((err) => alert(err.response.data.error.message))
    }, [])

    return (
        <Container className="flex">
            <Card>
                <Horzontal>
                    <Info>
                        <h1>Assinatura</h1>
                        <h4>Beta Tester</h4>

                        <PlanInfo>
                            <th>
                                <h3>Este plano inclui:</h3>
                            </th>
                            {plans.map((e, i) => (
                                <tr key={`plan_${i}`}>
                                    <div style={{ flexDirection: "column" }}>
                                        <h3 title={e.description}>
                                            {e.title}
                                            <FaQuestion
                                                color="#444"
                                                size={12}
                                            />
                                        </h3>
                                    </div>

                                    {!e.available ? (
                                        <MdRemoveCircleOutline color="#444" />
                                    ) : (
                                        <MdCheckCircleOutline color="#3f3" />
                                    )}
                                </tr>
                            ))}
                        </PlanInfo>
                    </Info>

                    <span />

                    <Info style={{ flex: 0, minWidth: 350 }}>
                        {stripePromise && clientSecret ? (
                            <div
                                style={{
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <Elements
                                    stripe={stripePromise}
                                    options={clientSecret}
                                >
                                    <CheckoutForm />
                                </Elements>
                            </div>
                        ) : (
                            <div
                                style={{
                                    flex: 1,
                                    justifyContent: "center",
                                    alignItems: "center",
                                }}
                            >
                                <ThreeDots color="black" />
                            </div>
                        )}
                    </Info>
                </Horzontal>
            </Card>
        </Container>
    )
}

export default Checkout
