const plans = [
    {
        title: "Prescrição de Treino",
        price: 19.99,
        description:
            "Desenvolva treinos personalizados e otimizados para cada aluno, com controle total sobre séries, repetições e exercícios, garantindo resultados mais eficientes.",
        available: false,
    },
    {
        title: "Avaliações em Consultas",
        price: 19.99,
        description:
            "Realize avaliações antropométricas detalhadas para acompanhar o progresso físico de seus alunos e ajustar planos de treinamento e nutrição de forma mais precisa.",
        available: false,
    },
    {
        title: "Análise de Progressão de Carga",
        price: 19.99,
        description:
            "Monitore a evolução de desempenho dos alunos em cada treino, com dados detalhados sobre o aumento de carga e progresso ao longo do tempo.",
        available: false,
    },
    {
        title: "Templates de Exercícios com Vídeo",
        price: 19.99,
        description:
            "Acesse mais de 400 exercícios com vídeos demonstrativos para facilitar a criação de treinos, proporcionando conteúdo profissional e pronto para uso imediato.",
        available: false,
    },
    {
        title: "Formulário de Anamnese",
        price: 19.99,
        description:
            "Em breve: um formulário completo de anamnese para conhecer melhor os seus alunos, seus objetivos e condições físicas, e personalizar os treinos de forma ainda mais eficaz.",
        available: false,
    },
]

export default plans
