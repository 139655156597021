import styled from "styled-components"

export const Container = styled.div`
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
`

export const Horzontal = styled.div`
    flex: 1;
    flex-direction: row;

    align-items: center;

    > span {
        height: 75%;
        width: 1px;
        background-color: #888;
    }
`

export const Info = styled.div`
    flex: 1;

    flex-direction: column;

    height: 100%;
`

export const PlanInfo = styled.table`
    flex: 1;
    justify-content: center;
    padding-right: 10px;
    flex-direction: column;
    > th {
        flex-direction: column;
        padding-block: 10px;
        * {
            font-weight: bold;
        }
    }
    > tr {
        flex-direction: row;
        padding-block: 5px;
        justify-content: space-between;
        padding: 7px;
        align-items: center;

        font-size: 14px;
    }

    h3 {
        align-items: center;
    }

    > tr:nth-child(even) {
        background-color: #f0f0f0;
    }
`

export const Card = styled.div`
    flex-direction: column;
    padding: 25px 0px 0px 25px;
    border-radius: 4px;
    border: 1px solid var(--panel);

    width: 50%;

    height: 50%;

    #payment-form {
        display: flex;
        width: 100%;
        flex: 1;
        flex-direction: column;
        align-items: center;
    }

    #submit {
        padding: 10px;
        background-color: blue;
        color: white;
        border-radius: 4px;
        margin: 10px;
    }
`

export const Product = styled.div``
