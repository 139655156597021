import React, { useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"

import {
    BasicInfo,
    ButtonsLayout,
    Column,
    StudentWrapper,
    Notes,
    AditionalInfo,
    Avatar,
    ConfigButton,
    NotificationsLayout,
    Note,
    AddNoteButton,
} from "./styles"

import note from "../../assets/svg/rectangle.svg"
import dummy from "../../assets/png/userd-demo-pic.png"
import { LargeButtonInfo } from "../../components/LargeButton"
import { HiChevronLeft } from "react-icons/hi"
import MutableIcon from "../../components/mutable-icon"
import api from "../../API/rest"
import mostrarAlerta from "../../components/utils/alert"
import {
    MdAddCircle,
    MdDeleteForever,
    MdFolder,
    MdPhoneAndroid,
} from "react-icons/md"
import StudentContext from "src/contexts/student"
import moment from "moment"
import { FaBirthdayCake, FaCog, FaLink } from "react-icons/fa"
import AuthContext from "src/contexts/auth"
import { TbActivityHeartbeat } from "react-icons/tb"
import { nanoid } from "nanoid"
import Activities from "./Activities"
import { ThreeDots } from "react-loader-spinner"

export type ActivityTypes = "FEEDBACK" | "MESSAGE"

export type Activity = {
    title: string
    subtitle: string
    data: any
    type: ActivityTypes
    date: string //ISO 8061 Date
}

export interface Student {
    id: string
    name: string
    username: string
    email: string
    phone: string
    gender: string
    birthdate: Date
    created_at: Date
    activities: Array<Activity>
    notes?: string[]
}

export default function StudentInfo() {
    const navigate = useNavigate()

    const { student } = useContext(StudentContext)

    const user = useContext(AuthContext).user

    const [userNotes, setUserNotes] = useState(student?.notes)

    function GetTraineeLink() {
        if (!student) return
        if (navigator.clipboard) {
            const link = `https://aluno.fithub.app.br/aluno?id=${student.id}`
            navigator.clipboard.writeText(link).then(() => {
                mostrarAlerta(
                    "Link do Aluno copiado para a Área de Transferência!"
                )
            })
        }
    }

    function UpdateNote(e: React.ChangeEvent<HTMLTextAreaElement>) {
        if (!userNotes) return

        const { name, value } = e.target

        setUserNotes((old) => {
            const n = [...(old ?? [])]
            n[parseInt(name)] = value
            return n
        })
    }

    function RemoveNote(index: number) {
        if (!userNotes) return

        const newNotes = [...userNotes]

        try {
            newNotes.splice(index, 1)
            setUserNotes(newNotes)
        } finally {
            UploadNote(newNotes)
        }
    }

    function AddNote() {
        if (!userNotes) return

        const newNotes = [...userNotes, ""]
        setUserNotes(newNotes)
        UploadNote(newNotes)
    }

    function UploadNote(data: string[]) {
        console.log("uploading notes:", data)
        if (!student) return
        api.post("/students/savenotes", {
            sid: student.id,
            tid: user?.id,
            data: data,
        }).catch((err) => {
            mostrarAlerta(err)
        })
    }

    if (!student) return <>Carregando...</>
    return (
        <StudentWrapper className="flex row">
            <Column>
                <BasicInfo>
                    <MutableIcon
                        icon={HiChevronLeft}
                        size={40}
                        onClick={() =>
                            navigate("/", { state: { keepModal: true } })
                        }
                    />
                    <div style={{ position: "relative" }}>
                        <Avatar src={dummy} />
                    </div>

                    <div
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            alignItems: "center",
                            paddingRight: 30,
                        }}
                    >
                        <div style={{ flexDirection: "column" }}>
                            <p>{student.name}</p>
                            <p
                                style={{
                                    fontSize: 12,
                                    color: "#666",
                                }}
                            >
                                {student.email}
                            </p>
                            <div
                                style={{
                                    paddingTop: 10,
                                    justifyContent: "space-between",
                                }}
                            >
                                <div
                                    style={{
                                        alignItems: "center",
                                        fontSize: 12,
                                    }}
                                >
                                    <FaBirthdayCake />
                                    <span style={{ margin: 2 }} />
                                    <p>
                                        {moment(student.birthdate).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </p>
                                </div>
                                <span style={{ margin: 5 }} />
                                <div
                                    style={{
                                        alignItems: "center",
                                        fontSize: 12,
                                    }}
                                >
                                    <MdPhoneAndroid />
                                    <span style={{ margin: 2 }} />
                                    <p>{student.phone}</p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <ConfigButton onClick={GetTraineeLink}>
                                <FaLink size={20} color="#444" />
                            </ConfigButton>

                            <ConfigButton onClick={() => navigate("settings")}>
                                <FaCog size={20} color="#444" />
                            </ConfigButton>
                        </div>
                    </div>
                </BasicInfo>
                <AditionalInfo>
                    <div>
                        <h4>Cadastro</h4>
                        <h5>Ativo</h5>
                    </div>
                    <span />
                    <div>
                        <h4>Aluno Desde</h4>
                        <h5>
                            {moment(student.created_at)
                                .format("MMMM [de] YYYY")
                                .toUpperCase()}
                        </h5>
                    </div>
                </AditionalInfo>
                {!userNotes ? (
                    <Notes
                        style={{
                            flex: 1,
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                    >
                        <ThreeDots color="black" />
                    </Notes>
                ) : (
                    <Notes>
                        <div>
                            <img src={note} alt="" />
                            <h4>Notas</h4>
                        </div>

                        <ul>
                            {userNotes &&
                                userNotes.map((e, index) => (
                                    <Note key={`note_${index}`}>
                                        <div
                                            style={{
                                                margin: 0,
                                                padding: 5,
                                            }}
                                        >
                                            <p>Nota {index + 1}</p>
                                            {index != 0 && (
                                                <MutableIcon
                                                    onClick={() =>
                                                        RemoveNote(index)
                                                    }
                                                    icon={MdDeleteForever}
                                                    color="#444"
                                                    size={24}
                                                />
                                            )}
                                        </div>
                                        <textarea
                                            name={index.toString()}
                                            key={`note_${index}`}
                                            id={nanoid()}
                                            value={e}
                                            placeholder="Escreva notas e lembretes do aluno..."
                                            onBlur={() => UploadNote(userNotes)}
                                            onChange={UpdateNote}
                                        />
                                    </Note>
                                ))}

                            <div
                                style={{
                                    flexDirection: "row-reverse",
                                }}
                            >
                                <AddNoteButton onClick={AddNote}>
                                    <MdAddCircle />
                                </AddNoteButton>
                            </div>
                        </ul>
                    </Notes>
                )}
            </Column>
            <span style={{ margin: 5 }} />
            <Column forceSize>
                <NotificationsLayout>
                    <div>
                        <div
                            style={{
                                alignItems: "center",
                                paddingBottom: 5,
                                color: "#444",
                            }}
                        >
                            <TbActivityHeartbeat style={{ marginRight: 5 }} />
                            <h4 style={{ fontWeight: "bold" }}>Atividade</h4>
                        </div>

                        <Activities activities={student.activities} />

                        <ButtonsLayout>
                            <LargeButtonInfo
                                disabled
                                icon={MdFolder}
                                title={"Anamnese"}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Avaliações"}
                                onClick={() => navigate("assessments")}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Protocolos de Treinos"}
                                onClick={() => navigate("workouts")}
                            />
                            <LargeButtonInfo
                                icon={MdFolder}
                                title={"Progressão de Carga"}
                                onClick={() => navigate("progression")}
                            />
                        </ButtonsLayout>
                    </div>
                </NotificationsLayout>
            </Column>
        </StudentWrapper>
    )
}
